import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import classNames from 'classnames';

import CTA from 'Src/components/buttons/CTA';
import { Flex } from 'Src/components/layout/Flex';
import { usePrintRetry } from 'Src/feature/check-print/usePrintRetry';
import useRoute from 'Src/routes/useRoute';

import { PrintFailureNotificationMessage } from './PrintFailureNotificationMessage';
import { CheckPrintJobFailure } from './types';
import { NotificationUI } from './useNotification';

import styles from './PrintFailureToastNotification.module.scss';

const sectionId = 'PrintFailureToastNotification';

interface PrintFailureToastNotificationProps {
  notification: NotificationUI<CheckPrintJobFailure>
  className?: string
  onAction?: () => void
}

const PrintFailureToastNotification: FC<PrintFailureToastNotificationProps> = ({
  notification,
  className,
  onAction,
  ...rest
}) => {
  const { t } = useTranslation();
  const { navigate } = useRoute();
  const printRetry = usePrintRetry.mutate.retry();
  const { data } = notification;

  return (
    <Flex
      {...rest}
      horizontal
      alignItems="center"
      className={classNames(styles.root, className)}
      gap="sm"
    >
      <PrintFailureNotificationMessage
        className={styles.message}
        data={data}
        onNavigate={(...args) => {
          navigate(...(args as Parameters<NavigateFunction>)); // TODO: shouldn't need casting here, but something weird in TS is making it required
          if (onAction) onAction();
        }}
      />
      <CTA
        color="primary"
        onClick={() => {
          void printRetry.mutateAsync({ checkId: data.checkId, printJobId: data.id });
          if (onAction) onAction();
        }}
        label={t('notification.printFailure.retry')}
      />
    </Flex>
  );
};

export type { PrintFailureToastNotificationProps };
export { PrintFailureToastNotification, sectionId };
