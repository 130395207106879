import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Copy from 'Src/components/Copy';
import { Flex } from 'Src/components/layout/Flex';
import useSocket from 'Src/network/useSocket';

const ProvisionedNotReadyLanding: FC = () => {
  const socket = useSocket();
  const { t } = useTranslation();

  const handleConnect = useCallback(() => window.location.reload(), []);

  useEffect(() => {
    // b/c handleReconnect is an async function, it thinks I should put `void` in front
    // however if I do that it complains that void is not assignable to the callback type
    socket.on('connected', handleConnect);

    return () => {
      socket.off('connected', handleConnect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleConnect]);

  return (
    <Flex fullHeight alignItems="center" justifyContent="center">
      <Copy value={t('labels.provisionedNotReady')} />
    </Flex>
  );
};

export { ProvisionedNotReadyLanding };