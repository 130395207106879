import React, { FC, useEffect } from 'react';

import useMountedState from 'Src/components/useMountedState';
import { useCurrentOperator } from 'Src/feature/operator/useCurrentOperator';
import { useOperator } from 'Src/feature/operator/useOperator';
import { useTerminal } from 'Src/feature/terminal/useTerminal';

import useSocket from './useSocket';
import { SocketAutoRetrierProvider } from './useSocketRetryOnReconnect';

let mountedCount = 0;
const sectionId = 'ReconnectionHandler';
/**
 * ReconnectionHandler monitors to see if our socket connection dropped then reconnected.
 * If it did, then it will await the terminal svc to re-initialize (which it automatically does on reconnection),
 * and then it will check to see if we had an Operator at the helm. If we did, it will auto-log in.
 * This should only be used once and at the root of the app.
 */
const ReconnectionHandler: FC = () => {
  const socket = useSocket();
  const { isUnmounted } = useMountedState();
  const isHandlingReconnect = React.useRef<boolean>(false);
  const terminalLocation = useTerminal.query.get();
  const isReady = useTerminal.isReady();
  const { currentOperator } = useCurrentOperator();
  const { mutateAsync: operatorLogin } = useOperator.mutate.login();

  const handleReconnect = React.useCallback(async () => {
    if (!isReady) return;
    isHandlingReconnect.current = true;
    try {
      await terminalLocation.refetch({ throwOnError: true });
      if (isUnmounted) return;
      if (currentOperator) {
        await operatorLogin({
          authNRequest: {
            pin: currentOperator.employee.pin,
            cardCode: currentOperator.employee.cardCode,
          },
        });
      }
      if (isUnmounted) return;
      isHandlingReconnect.current = false;
    } catch (err) {
      console.error(sectionId, 'Error encountered in ReconnectionHandler', err);
    }
  }, [currentOperator, isReady, isUnmounted, operatorLogin, terminalLocation]);

  // mount/unmount handler
  useEffect(() => {
    if (mountedCount > 0) {
      throw new Error(`${sectionId} should only be used one time`);
    }
    mountedCount += 1;

    return () => {
      mountedCount -= 1;
    };
  }, []);

  // socket subscription handler (socket ref won't change as it's a singleton)
  useEffect(() => {
    // b/c handleReconnect is an async function, it thinks I should put `void` in front
    // however if I do that it complains that void is not assignable to the callback type
    socket.on('reconnection', handleReconnect as () => void);

    return () => {
      socket.off('reconnection', handleReconnect as () => void);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleReconnect]);

  return (
    <SocketAutoRetrierProvider
      value={{
        socket,
        authMonitor: {
          get isReAuthInProgress(): boolean {
            return isHandlingReconnect.current;
          },
        },
      }}
    />
  );
};

export { ReconnectionHandler };
