import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppLoader, { deferredImport } from 'Src/components/AppLoader';
import { CheckExitPrompt } from 'Src/components/modals/CheckExitPrompt';
import { ClockoutPrompt } from 'Src/components/modals/ClockoutPrompt';
import { useDevTools } from 'Src/dev-tools/useDevTools';
import { useLogger } from 'Src/feature/context-logger/useLogger';
import { HasChangesPrompt } from 'Src/feature/has-changes/HasChangesPrompt';
import { NotificationListener } from 'Src/feature/notification/NotificationListener';
import { useCurrentOperator } from 'Src/feature/operator/useCurrentOperator';
import { clearLSToken } from 'Src/feature/terminal/terminalService';
import { TerminalUiChangeTracker } from 'Src/feature/terminal/TerminalUiChangeTracker';
import { useIsClockedIn } from 'Src/feature/terminal/useIsClockedIn';
import { isProvisioned, useTerminal } from 'Src/feature/terminal/useTerminal';
import { ToastRenderer } from 'Src/feature/toasts/ToastRenderer';
import { ReconnectionHandler } from 'Src/network/ReconnectionHandler';
import { ServerMessageDataModelUpdaters } from 'Src/network/ServerMessageDataModelUpdaters';
import useRoute from 'Src/routes/useRoute';
import useDOMEventListener from 'Src/utils/useDOMEvtListener';

import { ProvisionedNotReadyLanding } from './ProvisionedNotReadyLanding';

const POSRouter = deferredImport(() => import('Src/routes/POSRouter'));
const POSLogin = deferredImport(() => import('Src/feature/pos/POSLogin'));
const ProvisionRouter = deferredImport(() => import('Src/routes/ProvisionRouter'));
const TimeClockLogin = deferredImport(() => import('Src/feature/pos/TimeClockLogin'));
const DevToolsView = deferredImport(() => import('Src/dev-tools/DevToolsView'));

const sectionId = 'TerminalRouter';

const TerminalRouter: FC = () => {
  const log = useLogger().getSubLogger({ name: sectionId });
  const { navigate, crumbs, route } = useRoute();
  const devTools = useDevTools();
  const { currentOperator } = useCurrentOperator();
  const terminalLocation = useTerminal.query.get();
  const isReady = useTerminal.isReady();
  const { isClockedIn } = useIsClockedIn();

  useDOMEventListener('notify.TerminalReleaseNotification', ({ detail }) => {
    if (terminalLocation.data?.terminal.id == detail.termId) {
      // force un-provision this terminal
      clearLSToken();
      // redirect back to provisioner login
      navigate(crumbs.provision.main.path);
      // force a reload to clear out any persisting state
      window.location.reload();
      log.info('notify.TerminalReleaseNotification', 'window.location.reload()');
    }
  });

  let routes = <></>;
  if (terminalLocation.isLoading) routes = <AppLoader />;
  else if (isProvisioned() && !isReady) routes = <ProvisionedNotReadyLanding />;
  else if (!isProvisioned()) {
    routes = <ProvisionRouter />;
  } else {
    // redirect to clock in
    // We know who the terminal is, but we have no operator identified
    if (!isClockedIn || !currentOperator) {
      const redirectState = route.getRedirectState();
      const isPOSTerminal = terminalLocation.data?.terminal.role === 'POS';
      routes = (
        <Routes>
          <Route path="/pos/*" element={isPOSTerminal ? <POSLogin /> : <TimeClockLogin />} />
          <Route path="*" element={<Navigate to="/pos" replace state={redirectState} />}/>
        </Routes>
      );
    }
    // We know who the terminal is and have identified the operator
    else {
      routes = (
        <Routes>
          <Route path="/pos/*" element={<POSRouter />} />
          <Route path="*" element={<Navigate to="/pos" replace />}/>
        </Routes>
      );
    }
  }

  // terminalIsInitialized only happens once, so after that, we need to just return an observer that tracks if terminal is provisioned
  // which can change after initialization (e.g. - can become unprovisioned or provisioned)
  return (
    <>
      <ToastRenderer />
      <NotificationListener />
      <ServerMessageDataModelUpdaters />
      {isReady && <ReconnectionHandler />}
      {isReady && <TerminalUiChangeTracker />}
      {routes}
      {devTools.shouldUse ? <DevToolsView/> : undefined}
      <HasChangesPrompt />
      <CheckExitPrompt />
      <ClockoutPrompt />
    </>
  );
};

export { TerminalRouter };
