import React, { FC } from 'react';

import { ToastAutoHideDuration } from 'Src/consts/duration';
import { useToast } from 'Src/feature/toasts/useToast';
import useDOMEventListener from 'Src/utils/useDOMEvtListener';

import { AuthRequestToastNotification } from './AuthRequestToastNotification';
import { PrintFailureToastNotification } from './PrintFailureToastNotification';
import * as types from './types';
import { useIsMyNotification } from './useIsMyNotification';
import { NotificationUI, useNotification } from './useNotification';

const sectionId = 'NotificationListener';
const NotificationListener: FC = () => {

  const toast = useToast();
  const { add: addNotification, remove: removeNotification } = useNotification();
  const { isMyNotification } = useIsMyNotification();

  useDOMEventListener('notify.AuthorizationRequestCreated', (evt) => {
    const newNotification = new NotificationUI<types.AuthorizationRequestCreated>({
      type: 'authorizationRequest',
      id: evt.detail.id?.toString() as string,
      time: Date.now(),
      data: evt.detail,
    });

    addNotification(newNotification);

    if (isMyNotification(newNotification)) {
      toast.make({
        // sharing the same id, so we only show the last toast
        id: `${sectionId}.auth.request`,
        autoHideDuration: ToastAutoHideDuration.Notification,
        type: 'custom',
        render: (t) => (
          <AuthRequestToastNotification
            notification={newNotification}
            onAction={() => toast.store?.hide(t)}
          />
        )
      }).show();
    }
  });

  useDOMEventListener('notify.AuthorizationRequestAnswered', (evt) => {
    removeNotification(evt.detail.id?.toString() as string);
  });

  useDOMEventListener('notify.CheckPrintJobResult', (evt) => {

    // we only want to handle error states
    if (!evt.detail.errStr) return;

    const newNotification = new NotificationUI<types.CheckPrintJobFailure>({
      type: 'printFailure',
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      id: evt.detail.id?.toString(),
      time: Date.now(),
      data: evt.detail,
    });

    addNotification(newNotification);

    if (isMyNotification(newNotification)) {
      toast.make({
        // sharing the same id, so we only show the last toast
        id: `${sectionId}.print.failure`,
        autoHideDuration: ToastAutoHideDuration.Notification,
        type: 'custom',
        render: (t) => (
          <PrintFailureToastNotification
            notification={newNotification}
            onAction={() => toast.store?.hide(t)}
          />
        )
      }).show();
    }
  });

  useDOMEventListener('notify.CheckPrintJobActionTaken', (evt) => {
    evt.detail?.jobUids?.forEach((jobId) => removeNotification(jobId));
  });

  useDOMEventListener('notify.PrinterInfoChanged', (evt) => {
    if (!evt.detail.printer.id) return;
    // always remove the current status
    removeNotification(evt.detail.printer.id.toString());
    // only put a status into notifications if that status is not good
    if (evt.detail.printer.status !== 'Ready') addNotification(new NotificationUI<types.PrinterInfoChanged>({
      type: 'printerStatus',
      id: evt.detail.printer.id.toString(),
      time: Date.now(),
      data: evt.detail,
    }));
  });

  return null;
};

export { NotificationListener, sectionId };
