import React, { FC, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, createRoutesFromChildren, matchRoutes, Route, Routes, useLocation, useNavigationType } from 'react-router-dom';
import {
  createReactRouterV6Options,
  getWebInstrumentations,
  initializeFaro,
  LogLevel,
  ReactIntegration,
} from '@grafana/faro-react';

import AppContainer from 'Src/components/AppContainer';
import AppLoader, { deferredImport } from 'Src/components/AppLoader';
import { GlobalProvider } from 'Src/components/GlobalProvider';
import useEffectOnce from 'Src/components/useEffectOnce';
import { TerminalRouter } from 'Src/routes/TerminalRouter';
import { APP_NAME, APP_VERSION, FARO_LOGGING_URL } from 'Src/stores/appEnv';
import { log } from 'Src/utils/log';

import 'Src/styles/reset.scss';
import 'Src/styles/index.scss';

import './vendor/i18next/config';

const StyleGuide = deferredImport(() => import('Src/feature/style-guide/StyleGuide'));

const faro = initializeFaro({
  // Mandatory, the URL of the Grafana collector
  url: FARO_LOGGING_URL,
  // Mandatory, the identification label of your application
  app: {
    name: APP_NAME,
    version: APP_VERSION,
  },
  instrumentations: [
    // Load the default Web instrumentations
    ...getWebInstrumentations(),

    new ReactIntegration({
      router: createReactRouterV6Options({
        createRoutesFromChildren,
        matchRoutes,
        Routes,
        useLocation,
        useNavigationType,
      }),
    }),
  ],
});

// add faro as an additional transport
log.attachTransport((logObj) => {
  const { _meta, context, ...rest } = logObj;

  const convertedContext = Object.keys(context).reduce((acc, k) => {
    acc[k] = JSON.stringify(context[k]);
    return acc;
  }, {} as Record<string, string>);

  let faroLogLevel = LogLevel.LOG;
  switch (_meta.logLevelId) {
    case 1:
      faroLogLevel = LogLevel.TRACE;
      break;
    case 2:
      faroLogLevel = LogLevel.DEBUG;
      break;
    case 3:
      faroLogLevel = LogLevel.INFO;
      break;
    case 4:
      faroLogLevel = LogLevel.WARN;
      break;
    case 5:
    case 6: // there is no 'fatal' level in faro... translating that to 'error'
      faroLogLevel = LogLevel.ERROR;
      break;
    case 0:
    default:
      faroLogLevel = LogLevel.LOG;
  }
  faro.api.pushLog([JSON.stringify(rest)], { level: faroLogLevel, context: convertedContext });
});

const App: FC = () => {
  const { t } = useTranslation();

  // just to show logging is working....
  useEffectOnce(() => void log.info(t('app.startUp')));

  return (
    <AppContainer rootId="root" title={t('app.page.title')}>
      <Router>
        <GlobalProvider>
          {/* TODO:
            - resolve async components that have async inside of useEffects
            - in dev mode we get dual calls in use effects: see https://blog.bitsrc.io/react-v18-0-useeffect-bug-why-do-effects-run-twice-39babecede93
          */}
          {/*<React.StrictMode>*/}
          <Suspense fallback={<AppLoader />}>
            <Routes>
              {/* NO AUTH NEEDED FOR THESE ROUTES */}
              <Route path="/styleguide/*" element={<StyleGuide />} />
              <Route path="/*" element={<TerminalRouter />} />
            </Routes>
          </Suspense>
          {/*</React.StrictMode>*/}
        </GlobalProvider>
      </Router>
    </AppContainer>
  );
};

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);
root.render(<App />);



