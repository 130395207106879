import React, { FC, PropsWithChildren } from 'react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider, QueryKey } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

import { GlobalSearchProvider } from 'Src/components/useGlobalSearch';
import { CurrentAuthRequestProvider } from 'Src/feature/auth-request/useCurrentAuthRequest';
import { CardReaderProvider } from 'Src/feature/card-reader/useCardReader';
import { CurrentCheckStoreProvider } from 'Src/feature/check/useCurrentCheck';
import { LocationLoggerProvider } from 'Src/feature/context-logger/LocationLoggerProvider';
import { HasChangesProvider } from 'Src/feature/has-changes/useHasChanges';
import { HasChangesPromptProvider } from 'Src/feature/has-changes/useHasChangesPrompt';
import { CurrentMenuScreenProvider } from 'Src/feature/menu/useCurrentMenuScreen';
import { NotificationEventDispatcher } from 'Src/feature/notification/NotificationEventDispatcher';
import { queryKeys as notificationQueryKeys } from 'Src/feature/notification/useNotification';
import { CurrentOperatorProvider } from 'Src/feature/operator/useCurrentOperator';
import { CurrentPaymentProvider } from 'Src/feature/payment/useCurrentPayment';
import { IsClockedInProvider } from 'Src/feature/terminal/useIsClockedIn';
import { ToastStoreProvider } from 'Src/feature/toasts/useToast';
import { SocketProvider } from 'Src/network/useSocket';
import { AppConfigStoreProvider } from 'Src/stores/useAppConfig';

import { CheckExitPromptProvider } from './modals/useCheckExitPrompt';
import { ClockoutPromptProvider } from './modals/useClockoutPrompt';

// 11/2023 BK
// I can currently think of two types of data our system is holds. Below I will list out their properties and the options I would
// use for holding that data in react query.
// NOTE #1: The two data types assume we have messages coming over websockets to tell us when data is out of date. If for some
//          reason that is not true, the stale time should be addjusted.
// NOTE #2: The default settings below are setup for data type 1, which should be the most common in our system.
//  1. Data that is not prefetched (meaning loading it on demand is fast/acceptable). Here we want the stale time to be infinity
//     and the garbage collection time to be 5 mins (the default in react query already). The websocket message from the server can be handled by
//     simply invalidating the query, which will result in an up-to-date copy being fetched, but only if that data is currently being
//     used.
//  2. Data IS prefetched (meaning loading it is slow). Here we want the stale time to be infinity and the garbage collection time to be infinity.
//     Messages from the backend can either send updated data, which we can merge and store into the query client, or if the payload
//     doesn't have the updated info we can force a refetch. Forcing the refetch (rather than invalidating) is nessasary because if no
//     component is using that data, we need to still get the updated data from the backend.

const persistQueries: QueryKey[] = [notificationQueryKeys.all];
// All this does is retry up to three times if the backend times out. All other types of errors from the backend will be treated as
// errors straight away.
// Prior to react query, we didn't have a distinction between queries and mutations. React query does this... therefore we need defaults
// for both types. However, some options make sense only in the context of a query. For example, staleTime doesn't mean anything for
// a mutation because we don't store any data.
// The retry method however is applicable to both and therefore is set for both types (queries and mutations).
const retryFn = (failureCount: number, error: unknown) => {
  if (error instanceof Error && /timeout waiting for socket/i.test(error.message) && failureCount < 3) return true;
  return false;
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryFn,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
    mutations: {
      retry: retryFn,
    }
  },
});
queryClient.setQueryDefaults(notificationQueryKeys.all, { gcTime: Infinity, enabled: false });
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 0,
});
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  maxAge: Infinity,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => persistQueries.findIndex((pqk) => pqk.length === queryKey.length && pqk.every((val, idx) => val === queryKey[idx])) >= 0,
  },
});

const GlobalProvider: FC<PropsWithChildren> = ({ children }) => (
  <ToastStoreProvider>
    <SocketProvider>
      <QueryClientProvider client={queryClient}>
        <LocationLoggerProvider>
          <CardReaderProvider>
            <AppConfigStoreProvider>
              <HasChangesProvider>
                <HasChangesPromptProvider>
                  {/* provide a current checkUI model to all check routes w/ a "checkId" route param */}
                  <CurrentCheckStoreProvider>
                    <CheckExitPromptProvider>
                      <CurrentOperatorProvider>
                        <ClockoutPromptProvider>
                          <IsClockedInProvider>
                            <CurrentPaymentProvider>
                              <CurrentAuthRequestProvider>
                                <CurrentMenuScreenProvider>
                                  <GlobalSearchProvider>
                                    <NotificationEventDispatcher/>
                                    {children}
                                  </GlobalSearchProvider>
                                </CurrentMenuScreenProvider>
                              </CurrentAuthRequestProvider>
                            </CurrentPaymentProvider>
                          </IsClockedInProvider>
                        </ClockoutPromptProvider>
                      </CurrentOperatorProvider>
                    </CheckExitPromptProvider>
                  </CurrentCheckStoreProvider>
                </HasChangesPromptProvider>
              </HasChangesProvider>
            </AppConfigStoreProvider>
          </CardReaderProvider>
        </LocationLoggerProvider>
      </QueryClientProvider>
    </SocketProvider>
  </ToastStoreProvider>
);

export { GlobalProvider };
