import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import classNames from 'classnames';

import CTA from 'Src/components/buttons/CTA';
import { Flex } from 'Src/components/layout/Flex';
import { useAuthRequest } from 'Src/feature/auth-request/useAuthRequest';
import { AuthorizationRequestAnswered, AuthorizationRequestCreated } from 'Src/feature/notification/types';
import { useOnErrorShowToast } from 'Src/feature/toasts/useOnErrorShowToast';
import AuthorizationRequest, { ServerAuthorizationRequest } from 'Src/models/AuthorizationRequest';
import useRoute from 'Src/routes/useRoute';

import { AuthRequestNotificationMessage } from './AuthRequestNotificationMessage';
import { NotificationUI } from './useNotification';

import styles from './AuthRequestToastNotification.module.scss';

const sectionId = 'AuthRequestToastNotification';

interface AuthRequestToastNotificationProps {
  notification: NotificationUI<AuthorizationRequestCreated | AuthorizationRequestAnswered>
  className?: string
  onAction?: () => void
}

const AuthRequestToastNotification: FC<AuthRequestToastNotificationProps> = ({
  notification,
  className,
  onAction,
  ...rest
}) => {
  const { t } = useTranslation();
  const handleFormSubmitError = useOnErrorShowToast({ title: t('authRequest.errors.answer') });
  const { mutate: answerAuth } = useAuthRequest.mutate.answer(handleFormSubmitError);
  const { navigate } = useRoute();

  const { data } = notification;

  return (
    <Flex
      {...rest}
      horizontal
      alignItems="center"
      className={classNames(styles.root, className)}
      gap="sm"
    >
      <AuthRequestNotificationMessage
        className={styles.message}
        data={AuthorizationRequest.fromServer(data as ServerAuthorizationRequest)}
        onNavigate={(...args) => {
          navigate(...(args as Parameters<NavigateFunction>)); // TODO: shouldn't need casting here, but something weird in TS is making it required
          if (onAction) onAction();
        }}
      />
      <CTA
        color="secondary"
        onClick={() => {
          answerAuth({ authRequestId: data.id as number, outcome: 'Denied' });
          if (onAction) onAction();
        }}
        label={t('labels.deny')}
      />
      <CTA
        color="primary"
        onClick={() => {
          answerAuth({ authRequestId: data.id as number, outcome: 'Approved' });
          if (onAction) onAction();
        }}
        label={t('labels.approve')}
      />
    </Flex>
  );
};

export type { AuthRequestToastNotificationProps };
export { AuthRequestToastNotification, sectionId };
