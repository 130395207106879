import React, { FC, PropsWithChildren, useMemo } from 'react';

import { useTerminal } from 'Src/feature/terminal/useTerminal';

import { LoggerContext, useLogger } from './useLogger';

const LocationLoggerProvider: FC<PropsWithChildren> = ({ children }) => {
  const log = useLogger();
  const { data: terminalLocation } = useTerminal.query.get();
  const locationId = terminalLocation?.location.id;
  const locationIdLogger = useMemo(() => {
    if (locationId) return log.with('locationId', locationId);
    return log;
  }, [locationId, log]);
  return <LoggerContext.Provider value={locationIdLogger}>{children}</LoggerContext.Provider>;
};

export { LocationLoggerProvider };