import React, { createContext, FC, PropsWithChildren, useContext, useRef, useState } from 'react';

interface ButtonConfig {
  columns: number;
  rows: number;
  showMoreMenu?: boolean;
  inViewButtonCount: number;
}

type UsedAppConfigStore = ReturnType<typeof useAppConfigStore>;

const useAppConfigStore = () => {
  const [checkFooterVariant, checkFooterVariantSet] = useState('fill' as 'fill' | 'contained');
  const [checkFooterMaxRows, checkFooterMaxRowsSet] = useState(1);
  const [checkFooterUseMoreMenu, checkFooterUseMoreMenuSet] = useState(true);
  const [checkFooterAllButtonCount, checkFooterAllButtonCountSet] = useState(0);

  const checkFooterGetColumns = (config: ButtonConfig): number => {
    // menu button takes up one column
    let columns = config.columns;
    if (checkFooterVariant === 'fill') {
      columns = config.showMoreMenu ? 6 : 7;
    }
    if (checkFooterVariant === 'contained') {
      columns = config.showMoreMenu ? 3 : 4;
    }
    return columns;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkFooterGetRows = (config: ButtonConfig): number =>
    // TODO: increment rows when too many rows are configured for `inViewButtonCount`
    // JG - currently this is probably overly complex... we are sorting through our button configuration still
    // const rows = config.rows === undefined ? this.maxRows : config.rows;
    checkFooterMaxRows;

  const checkFooterButtonConfig = (config?: Partial<ButtonConfig>): ButtonConfig => {
    let _config: ButtonConfig = Object.assign(
      { showMoreMenu: undefined, rows: 0, columns: 0, inViewButtonCount: 0 },
      config,
    ) as ButtonConfig;
    _config.showMoreMenu = config?.showMoreMenu === undefined ? checkFooterUseMoreMenu : config?.showMoreMenu;
    _config.columns = checkFooterGetColumns(_config);
    _config.rows = checkFooterGetRows(_config);
    _config.inViewButtonCount = _config.columns * _config.rows;
    if (_config.showMoreMenu) {
      // reset configuration based on available space for buttons
      if (_config.inViewButtonCount >= checkFooterAllButtonCount) {
        _config.showMoreMenu = false;
        _config = checkFooterButtonConfig(_config);
      }
    }
    return _config;
  };

  const footerAction = useRef({
    setVariant: checkFooterVariantSet,
    setMaxRows: checkFooterMaxRowsSet,
    setUseMoreMenu: checkFooterUseMoreMenuSet,
    setAllButtonCount: checkFooterAllButtonCountSet,
    buttonConfig: checkFooterButtonConfig,
  });

  const footerState = {
    variant: checkFooterVariant,
    maxRows: checkFooterMaxRows,
    useMoreMenu: checkFooterUseMoreMenu,
    allButtonCount: checkFooterAllButtonCount,
  } as const;

  return {
    layout: {
      check: {
        footer: {
          state: footerState,
          action: footerAction.current,
        },
      },
    },
  };
};

const AppConfigStoreContext = createContext<UsedAppConfigStore | undefined>(undefined);
AppConfigStoreContext.displayName = 'AppConfigStoreContext';


const AppConfigStoreProvider: FC<PropsWithChildren> = ({ children }) => {
  const store = useAppConfigStore();
  return (
    <AppConfigStoreContext.Provider value={store}>
      {children}
    </AppConfigStoreContext.Provider>
  );
};

const useAppConfig = () => {
  const store = useContext(AppConfigStoreContext);
  if (!store) throw new Error('useAppConfig() must be used within a AppConfigStoreProvider');
  return store;
};

export { AppConfigStoreProvider, useAppConfig, useAppConfigStore };
export type { ButtonConfig, UsedAppConfigStore };
