import React, { FC } from 'react';

import { useCheckHeader } from 'Src/feature/check/useCheckHeader';
import { useEOD } from 'Src/feature/eod/useEOD';
import { useFloorPlan } from 'Src/feature/floor-plan/useFloorPlan';

// 2024/07 BK
// I realize the name for this component is not great. A good name would have been NotificationListeners. But this is mounted
// right next to another component that is called NotificationListener in the TerminalRouter. That component is used to listen
// for things that are considered "notifications" from the users perspective... so auth notifications, printer failures, etc.
// So notifications are a loaded term. So I chose something else to be different.

/**
 * ServerMessageDataModelUpdaters groups all of the updaters together. Think updates from other terminals coming over the websocket.
 */
const ServerMessageDataModelUpdaters: FC = () => {
  // notification listener(s) (updates from other terminals)
  useFloorPlan.mutate.onUpdate();
  useCheckHeader.mutate.onUpdate();
  useEOD.mutate.onEOD();
  
  return null;
};

export { ServerMessageDataModelUpdaters };
