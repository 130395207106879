import React, { createContext, useContext, useEffect } from 'react';

import { Socket, SocketState } from 'Src/models/socket';

interface SocketAutoRetrierContext {
  socket: Socket;
  // authMonitor tells us if there is a re-auth taking place so we know to hold up on the auto-retry upon reconnect until that's done
  authMonitor?: {
    isReAuthInProgress: boolean;
  };
}

const Context = createContext<SocketAutoRetrierContext | null>(null);
const SocketAutoRetrierProvider = Context.Provider;

interface SocketRetryOnReconnectOpts {
  // This optional - if you don't want it to retry, this hook is benign.
  // Main reason for this is that you cannot conditionally use hooks.
  onRetry?: () => void;
}

const useSocketRetryOnReconnect = ({ onRetry }: SocketRetryOnReconnectOpts): void => {
  const connectionCheckIntv = React.useRef(0);
  const ctx = useContext(Context);

  useEffect(() => {
    if (!(!!ctx && !!onRetry)) {
      return;
    }

    const checkConnectionReEstablished = (): void => {
      if (ctx.socket.status === SocketState.open && !ctx.authMonitor?.isReAuthInProgress) {
        window.clearInterval(connectionCheckIntv.current);
        onRetry();
      }
    };

    if (ctx.socket.status !== SocketState.open || ctx.authMonitor?.isReAuthInProgress) {
      connectionCheckIntv.current = window.setInterval(checkConnectionReEstablished, 100);
      // eslint-disable-next-line consistent-return
      return (): void => window.clearInterval(connectionCheckIntv.current);
    }
  }, [ctx, onRetry]);
};

export default useSocketRetryOnReconnect;
export { SocketAutoRetrierProvider };
export type { SocketAutoRetrierContext, SocketRetryOnReconnectOpts };
