import { useSearchParamsControl } from 'Src/routes/useSearchParams';
import { IS_LOCAL_DEV } from 'Src/stores/appEnv';
import { JSONLocalStorage } from 'Src/stores/JSONLocalStorage';

const storageKey = 'dev-tools';

// persists across browsing sessions - use this if you want dev tools _always_ on.
const storage = new JSONLocalStorage<string>(storageKey);

// persist for current browsing session - useful in deployed envs where query string is used to turn dev tools on
// and then the app routes elsewhere so this can persist even when query string param goes away.
const seshStore = {
  get: () => window.sessionStorage.getItem(storageKey) === 'true',
  set: (val: string) => window.sessionStorage.setItem(storageKey, val),
};

const useDevTools = () => {
  const searchParam = useSearchParamsControl<['true', 'false']>({ key: storageKey });
  const searchParamEnables = searchParam.values.hasAny && searchParam.values.includes('true');
  const searchParamDisables = searchParam.values.hasAny && searchParam.values.includes('true');
  if (searchParamEnables) {
    seshStore.set('true');
  }
  // this allows you to explicitly disable it in development
  const explicitlyDisabled = searchParamDisables || storage.get() === 'false';
  const explicitlyEnabled = searchParamEnables || storage.get() === 'true';

  // we want it enabled by default everywhere, but production
  // you can enable the DevTools via localStorage or the query string
  // DevTools can also be supported in production this way
  const shouldUse = !explicitlyDisabled && (IS_LOCAL_DEV || explicitlyEnabled || seshStore.get());

  return {
    shouldUse,
  };
};

export { useDevTools };
