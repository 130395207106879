import React, { useEffect } from 'react';

import useSocket from 'Src/network/useSocket';

import NotificationProcessor from './NotificationProcessor';

/**
 * @description - component that hooks into the socket and processes socket events into DOM Events
 */
const NotificationEventDispatcher: React.FC = () => {
  const socket = useSocket();
  useEffect(() => {
    if (socket) new NotificationProcessor(socket);
  }, [socket]);
  return null;
};

export { NotificationEventDispatcher };
