import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useClockoutPrompt } from 'Src/components/modals/useClockoutPrompt';
import { ModalAction } from 'Src/feature/modal/ModalAction';
import { TextModal } from 'Src/feature/modal/TextModal';

const sectionId = 'ClockoutPrompt';

const ClockoutPrompt: FC = () => {
  const { t } = useTranslation();
  const clockoutPrompt = useClockoutPrompt();

  useEffect(() => {
    clockoutPrompt.store.setPromptMounted(true);
    // dispose
    return () => {
      clockoutPrompt.store.setPromptMounted(false);
    };
  }, [clockoutPrompt.store]);

  return (
    <TextModal
      data-testid={sectionId}
      open={clockoutPrompt.store.isOpen}
      color="warning"
      textContent={t('clockOut.prompt.message')}
      actions={(
        <>
          <ModalAction
            data-testid={`${sectionId}-cancel-btn`}
            color="secondary"
            label={t('clockOut.prompt.cancel')}
            onClick={clockoutPrompt.store.onCancel}
          />
          <ModalAction
            data-testid={`${sectionId}-go-to-cash-banks-btn`}
            color="primary"
            label={t('clockOut.prompt.goToCashBanks')}
            onClick={clockoutPrompt.store.onConfirm}
          />
        </>
      )}
    />
  );
};

export { ClockoutPrompt };
