import React, { FC, useCallback, useEffect, useRef } from 'react';

import { useLogger } from 'Src/feature/context-logger/useLogger';
import useRoute from 'Src/routes/useRoute';
import useDOMEventListener from 'Src/utils/useDOMEvtListener';

import { useTerminal } from './useTerminal';

const sectionId = 'TerminalUiChangeTracker';

/**
 * TerminalUiChangeTracker
 * @description - Component that controls the Auto-logout logic.  Based on current terminal?.terminalSettings?.autoLogoutAfterSecs
 */
const TerminalUiChangeTracker: FC = () => {
  const log = useLogger().getSubLogger({ name: sectionId });
  const { navigate, crumbs } = useRoute();
  const terminalLocation = useTerminal.query.get();

  const timerRef = useRef<NodeJS.Timeout>();

  const onTimerEnd = useCallback(() => {
    log.info('onTimerEnd()');
    navigate(crumbs.pos.user.logout.path);
  }, [crumbs.pos.user.logout.path, log, navigate]);

  const updateTimer = useCallback(() => {
    if (!terminalLocation.data) return;
    if (terminalLocation.data.terminal.terminalSettings.autoLogoutAfterSecs === 0) return;
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(onTimerEnd, terminalLocation.data.terminal.terminalSettings.autoLogoutAfterSecs * 1000);
  }, [onTimerEnd, terminalLocation.data]);

  useDOMEventListener('click', () => {
    updateTimer();
  });
  useDOMEventListener('touchstart', () => {
    updateTimer();
  });

  useEffect(() => {
    updateTimer();
    return () => clearTimeout(timerRef.current);
  }, [updateTimer]);

  return <></>;
};

export { TerminalUiChangeTracker };
