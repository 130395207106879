import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAction } from 'Src/feature/modal/ModalAction';
import { TextModal } from 'Src/feature/modal/TextModal';

import { useCheckExitPrompt } from './useCheckExitPrompt';

const sectionId = 'CheckExitPrompt';

const CheckExitPrompt: FC = () => {
  const { t } = useTranslation();
  const checkExitPrompt = useCheckExitPrompt();

  useEffect(() => {
    checkExitPrompt.store.setPromptMounted(true);
    // dispose
    return () => {
      checkExitPrompt.store.setPromptMounted(false);
    };
  }, [checkExitPrompt.store]);

  return (
    <TextModal
      data-testid={sectionId}
      open={checkExitPrompt.store.isOpen}
      color="warning"
      title={t('checkExit.prompt.title')}
      textContent={t('checkExit.prompt.message', { count: checkExitPrompt.store.getCheckData().unSentCheckItemIds.length })}
      actions={(
        <>
          <ModalAction
            data-testid={`${sectionId}-cancel-btn`}
            color="secondary"
            onClick={checkExitPrompt.store.onCancel}
            label={t('checkExit.prompt.goBack')}
          />
          <ModalAction
            data-testid={`${sectionId}-continue-btn`}
            color="primary"
            onClick={checkExitPrompt.store.onConfirm}
            label={t('checkExit.prompt.send')}
          />
        </>
      )}
    />
  );
};

export { CheckExitPrompt, sectionId };
